import * as React from 'react'
import { Link } from 'gatsby'
let Scroll = require('react-scroll');
let scroller = Scroll.scroller;
var Element = Scroll.Element;

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import styled from "@emotion/styled";
const P = styled.p`
font-weight: normal;
font-size: 1.1em;
letter-spacing: 0.1em;
color: #333;
padding-bottom: 2.5em;
line-height: 1.5em;`

const B = styled.h3`
font-weight:bold;
`
const Div=styled.div`
  @media screen and (max-width: 870px) {
text-align:center; }
`


class Faq extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    scroller.scrollTo('header', {
      duration: 1500,
      smooth: true,
    })
  }


  render() {
    return (
  <IndexLayout>
    <Page>
      <Container>
        <br/>
        <br/>
        <Div>
<h1>FAQ</h1>
        </Div>
        <br/>
        <br/>
        <B>Welche Mitgliedschaftstarife gibt es?</B>
        <br/>
        <P>Wir bieten keine Mitgliedsbeiträge an. Dafür Officelösungen für 1-5 Personen zu einem fairen Paketpreis.</P>


        <B>Wie schnell kann ich buchen?</B>
        <br/>
        <P>Unsere Private Offices sind innerhalb von 24 Stunden für Sie buchbar.</P>

        <B>Welche Kündigungsfristen gelten?</B>
        <br/>
        <P>Unsere Pakete haben eine Kündigungsfrist von einer Woche.</P>

        <B>Gibt es Parkplätze?</B>
        <br/>
        <P>Es gibt öffentliche, kostenfreie Parkplätze in der Umgebung.</P>


        <B>Wie sind die Öffnungszeiten?</B>
        <br/>
        <P>Ihr Private Office steht Ihnen 24/7 zur Verfügung. Die Rezeption ist von 6:30 bis 18:00 Uhr besetzt.</P>

        <B>Bekomme ich einen eigenen Schlüssel?</B>
        <br/>
        <P>Sie erhalten mindestens einen Schlüssel. Bei Bedarf auch weitere, es ist eine Schlüsselversicherung notwendig.</P>

        <B>Kann ich abends arbeiten?</B>
        <br/>
        <P>Ihr Private Office steht Ihnen 24/7 zur Verfügung. Der Konferenzraum von 10:00 bis 22:00 Uhr.</P>

        <B>Kann ich mir Post zusenden lassen?</B>
        <br/>
        <P>Wir nehmen Ihre Post gerne entgegen und hinterlegen sie an der Rezeption.</P>

        <B>Kommen noch weitere Kosten auf mich zu?</B>
        <br/>
        <P>Unsere tägliche Reinigung und Desinfektion ist für Sie natürlich inklusive.
          Weitere Kosten können, je nach Verbrauch der Minibar,
          unserem zubuchbaren Frühstücksservice und der Buchung des Konferenzraums entstehen.</P>
        <B>Wie komme ich außerhalb der Öffnungszeiten ins V7?</B>
        <br/>
        <P>Mit Ihren Schlüssel(n) haben Sie stets freien Zugang zu Ihrem Private Office. </P>

        <B>Kann ich meinen eigenes Equipment mitbringen?</B>
        <br/>
        <P>Ja. Wir bieten unseren Gästen lediglich eine Basisausstattung an.
          Ihre genauen Vorstellungen können Sie gerne mit uns besprechen und gewünschte Ausstattung dazubuchen.</P>

        <B>Gibt es einen Drucker, Kopierer, Scanner?</B>
        <br/>
        <P>Es eine Drucker/Kopier/Scan-Station (Verbindung via WLAN). Ebenfalls werden Toner und Druckerpapier von uns gestellt. Generell gilt hier “Fair Use” unter allen Coworkern.</P>

        <B>Wie buche ich den Konferenzraum?</B>
        <br/>
        <P>Der Konferenzraum ist je nach Verfügbarkeit für 25 €/h zwischen 10:00 bis 22:00 für Sie an der Rezeption buchbar. </P>

        <B>In welchem Stock befindet sich das V7?</B>
        <br/>
        <P>Unsere Private Offices befinden sich im Hochparterre eines renovierten Altbaus (4 Stufen).</P>



        <B>Welche technische Ausstattung bietet das V7?</B>
        <br/>
        <P>Wir bieten eine Basisausstattung. Weitere technische Ausstattung auf Anfrage ausleihbar.</P>

        <B>Vorbeischauen oder Besichtigungstermin möglich?</B>
        <br/>
        <P>Nach Anmeldung können die Räumlichkeiten gerne besichtigt werden. Schicken Sie uns eine Nachricht
          an <a href={'mailto:info@vilhelm7coworking.de'}>info@vilhelm7coworking.de</a>.</P>

        <B>Wie groß ist der Konferenzraum?</B>
        <br/>
        <P>Unser 70qm großer Konferenzraum bietet Platz für max. 15-20 Personen.</P>

        <B>Sind die Offices geeignet für Menschen mit Handicap?</B>
        <br/>
        <P>Unser Coworking Space liegt im Hochparterre (4 Stufen) und ist daher nicht rollstuhlgerecht.</P>

        <B>Wie sind die Stornierungskosten?</B>
        <br/>
        <P>Stornierungen bis zu 1 Woche vor Beginn des Mietzeitraumes sind kostenfrei. Danach berechnen wir Ihnen einen vollen Wochenpreis des gebuchten Office-Paketes.</P>


        <B>Wann muss ich bezahlen?</B>
        <br/>
        <P>Das Basis Office-Paket ist immer im Voraus zu bezahlen.</P>


        <B>Wie kann ich bezahlen?</B>
        <br/>
        <P>Wir bieten Ihnen alle Zahlungsmöglichkeiten an. EC-Karte, Kreditkarte, PayPal, Überweisung, Barzahlung.</P>




        <B>Gibt es eine Kaution?</B>
        <br/>
        <P>Unsere Kaution für eine Wochenbuchung beläuft sich auf einmalig 500,- Euro. Für eine Monatsbuchung berechnen wir einmalig 1500,- Euro. Die Kaution ist vor Mietbeginn zu begleichen.</P>
        <br/>
        <P>Es ist möglich, die Kaution auf Ihrer Kreditkarte zu hinterlegen.</P>









      </Container>
    </Page>
  </IndexLayout>
    )
  }
}

export default Faq
